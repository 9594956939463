import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';

export default function BasicRowEditingGrid(props) {
  //console.log(props.jobs);

  return (
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid editMode="row" rows={props.jobs} columns={columns} processRowUpdate={props.onUpdate} onProcessRowUpdateError={props.onError}/>
    </div>
  );
}

const columns = [
  { field: 'desc', headerName: 'Job Description', width: 200, editable: true },
  {
    field: 'startDT',
    headerName: 'Start Time',
    type: 'dateTime',
    editable: true,
    align: 'left',
    headerAlign: 'left',
    width: 200
  },
  {
    field: 'duration',
    headerName: 'Duration',
    type: 'number',
    editable: true,
    align: 'left',
    headerAlign: 'left',
    width: 100
  },
  { field: 'contactName', headerName: 'Name', width: 150, editable: true },
  { field: 'contactPhone', headerName: 'Phone', width: 100, editable: true },
  { field: 'contactAddress', headerName: 'Address', width: 380, editable: true },
];
