import React, {useRef, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DBEditor from './DBEditor';

const protocol = window.location.protocol;
const host = window.location.hostname;

function Dashboard() {
  const [db, setDb] = useState({});

  useEffect(() => {
    console.log(protocol, host)
    fetch(`${protocol}//${host}:49033/db`)
    .then(res => res.json())
    .then((json) => {
      json["jobs"].forEach((j) => {
        j.startDT = new Date(j.start);
      })
      setDb(json);
      console.log(json);
    })
  
    return () => { 
    }
    
  }, [""]);

  const onUpdate = (jobs) => {
    jobs.start = jobs.startDT.toISOString();

    fetch(`${protocol}//${host}:49033/db`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jobs)
    }).then(res => res.json())
    .then((json) => {
      json["jobs"].forEach((j) => {
        j.startDT = new Date(j.start);
      })
      setDb(json);
    })

    return jobs;
  }

  const onUpdateError = (error) => {
    console.log(error);
  }

  const onResetClicked = () => {
    fetch(`${protocol}//${host}:49033/resetDB`);
  }

  return (
    <div>  
      <DBEditor jobs={db["jobs"] || []} onUpdate={onUpdate} onError={onUpdateError}/>  
      <Button onClick={onResetClicked}>Reset</Button>
    </div>
  );
}

export default Dashboard;
